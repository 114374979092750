<template>
  <div>
    <Breadcrumb />
    <b-card :title="$t('MySites.Title')">
      <div>
        <table-default class='table-sites' v-if="rows.length > 0" :rows="rows" :fields="fields" :stickyHeader="false"
          :key="`table-sites-${controlRenderTable}`">
          <template v-slot:cell(status)="data">
            <b-badge :variant="statusVariant(data.item.status)">
              {{ data.item.status == 1 ? $t('MySites.Active') : $t('MySites.Inactive') }}
            </b-badge>
          </template>

          <template v-slot:cell(action)="data">
            <div @click="deleteAllData(data.item)" class="btn-delete" :id="`tooltip-clean-site-${data.index}`">
              <LimparIcon />
            </div>
            <b-tooltip custom-class="tooltip-sites" triggers="hover blur" :target="`tooltip-clean-site-${data.index}`" positioning="top"
              placement="top" boundary-padding="0">
              <div class="info-tooltip-sites">
                {{ $t('MySites.CleanData') }}
              </div>
            </b-tooltip>
          </template>

          <template v-slot:cell(description)="data">
            <div class="add-ellipsis">
              <span :id="`tooltip-site-description-${data.index}`">
                {{ data.item.description }}
              </span>
            </div>
            <b-tooltip custom-class="tooltip-sites" triggers="hover blur" :target="`tooltip-site-description-${data.index}`" positioning="top"
              placement="top">
              <div class="info-tooltip-sites">
                {{ data.item.description }}
              </div>
            </b-tooltip>
          </template>
        </table-default>

      </div>
    </b-card>
  </div>
</template>

<script>
import Breadcrumb from '../../../layouts/components/Breadcrumb.vue'
import {
  BRow, BTooltip, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BAlert, BDropdown, BDropdownItem, BCard, BSidebar, VBToggle, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TableDefault from "@core/components/table-default/TableDefault.vue";
import LimparIcon from "@/assets/images/pages/limpar-icon.svg"
import { mapMutations } from "vuex";

export default {
  components: {
    BRow,
    VueGoodTable,
    BTooltip,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    ToastificationContent,
    BSidebar,
    BButton,
    Breadcrumb,
    TableDefault,
    LimparIcon
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      pageLength: 10,
      dir: false,
      currentlyStatusId: '',
      fields: [
        {
          label: 'IntegrationPage.Actions',
          key: 'action',
          sortable: false,
          class: `${JSON.parse(localStorage.getItem('userData')).ability.find(element => element.action === 'manage') ? '' : 'd-none'}`
        },
        {
          label: 'MySites.Name',
          key: 'name',
          sortable: true
        },
        {
          label: 'MySites.Description',
          key: 'description',
          sortable: true,
          class: 'col-name-type'
        },
        {
          label: 'STATUS',
          key: 'status',
          sortable: true
        },
      ],
      rows: [],
      searchTerm: '',
      controlRenderTable: 0,
    }
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    getSites() {
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/tenant-site`)
        .then(response => {
          this.rows = (response.data);
          this.UPDATE_FLAG_SHOW_OVERLAY(false);
        })
        .catch(error => {
          this.showToast('Error!', 'FrownIcon', 'An error occurred while getting your data!', 'danger')
          this.UPDATE_FLAG_SHOW_OVERLAY(false);
        })

    },

    turnSiteDefault(site) {
      this.$http.put(`/api/${this.$router.currentRoute.params.prefix}/tenant-site/${site.guid}`)
        .then(response => {
          this.getSites()
          localStorage.setItem('currentSite', JSON.stringify(site))
          this.showToast('Good job!', 'SmileIcon', 'Site updated successfully', 'success')
        })
        .catch(error => {
          this.showToast('Error!', 'FrownIcon', error.response.data.message || 'An error occurred while updating your data!', 'danger')
        })
    },

    deleteAllData(site) {
      this.$swal({
        title: this.$t('IntegrationPage.attention'),
        text: this.$t('MySites.deleteDataSite'),
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: this.$t('MySites.cancel'),
        confirmButtonText: this.$t('MySites.delete'),
        customClass: {
          container: 'swal-conection',
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.UPDATE_FLAG_SHOW_OVERLAY(true);
          this.$http.delete(`/api/${this.$router.currentRoute.params.prefix}/tenant-site/${site.guid}`)
            .then(response => {
              this.showToast(this.$t('MySites.success'), 'SmileIcon', this.$t('MySites.message'), 'success')
              this.getSites();
              this.controlRenderTable++;
            })
            .catch(error => {
              this.showToast('Error!', 'FrownIcon', error.response.data.message || 'An error occurred while updating your data!', 'danger')
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            })
        }
      })
    },

    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getSites()
  },
}
</script>


<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';

.tooltip-sites {
  //não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0;
  .tooltip-inner {
      padding: 0;
      max-width: 100%;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;

      .info-tooltip-sites {
          max-width: 100%;
          padding: 7px 8px;
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          letter-spacing: 0.16px;
          border: 1px solid transparent;
      }
  }

  .arrow {
      &::before {
          border-top-color: #323232;
          border-bottom-color: #323232;
      }
  }
}

.overlay-mensage{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.table-sites {
  .add-ellipsis {
    white-space: nowrap;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  thead th {
    padding: 10.5px 9px !important;
  }
  
  th:nth-child(1) {
    padding: 10.5px 7.65px !important;
  }

  td {
    padding: 14.5px 10px !important;
    height: 38px !important;
    letter-spacing: 0;
    border: 1px solid #E0E0E0 !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  td:nth-child(1) {
    text-align: center !important;
  }
  th:nth-child(1),
  td:nth-child(1) {
    min-width: 57px !important;
  }

  th:nth-child(2),
  td:nth-child(2) {
    min-width: 217px !important;
    max-width: calc(33.33vw - (57px / 3 ) - (2 * 16.8px) + 7px) !important;
    width: calc(33.33vw - (57px / 3 ) - (2 * 16.8px) + 7px)  !important;
  }

  
  th:nth-child(3),
  td:nth-child(3) {
    min-width: 217px !important;
    width: calc(33.33vw - (57px / 3 ) - (2 * 16.8px) + 7px) !important;
    max-width: calc(33.33vw - (57px / 3 ) - (2 * 16.8px) + 7px) !important;
  }
  
  th:nth-child(4),
  td:nth-child(4){
    min-width: 217px !important;
    width: calc(33.33vw - (57px / 3 ) - (2 * 16.8px) + 7px) !important;
    max-width: calc(33.33vw - (57px / 3 ) - (2 * 16.8px) + 7px) !important;
  }
}

.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;

      }
    }
  }

  & .badge-light-success {
    color: #28c76f !important;
  }
}

.card-title {
  font-weight: 600 !important;
}

.btn-delete {
  margin: 0 auto;
  width: 32px;
  height: 28px;
  border-radius: 5px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none !important;
  background-color: transparent !important;
}

.btn-delete:hover {
  cursor: pointer;
  box-shadow: none !important;
  background-color: #ffdbc4 !important;
}

.btn-delete:focus {
  background-color: #ffdbc4 !important;
}

.btn-delete:active {
  background-color: #ffdbc4 !important;
}

.swal-conection {
  .swal2-modal {
    padding: 0 !important;
    border-radius: 6px;
    width: 710px;

    .swal2-header {
      background-color: #D32F2F;
      padding: 20px 16px;
      border-radius: 6px 6px 0px 0px;
      flex-direction: row;
      justify-content: left;

      .swal2-title {
        color: #ffff;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin: 0;
      }

      .swal2-close {
        border-radius: 6px;
        width: 34px;
        background-color: #ffff;
        height: 34px;
        top: -5px;
        position: absolute;
        right: -5px;
      }
    }

    .swal2-content {
      padding: 20px 16px;

      .swal2-html-container {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4C4541;
        display: flex !important;

      }
    }

    .swal2-actions {
      padding: 0 16px 20px 16px;
      display: flex;
      justify-content: right;

      .swal2-confirm {
        margin-left: 10px !important;
        order: 2;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        color: #ffff;
        border-color: #974900 !important;
        background-color: #974900 !important;
        border-radius: 5px;

        &:hover {
          box-shadow: none !important;
        }
      }

      .swal2-cancel {
        order: 1;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        color: #974900 !important;
        border-radius: 5px;
        border-color: transparent !important;

        &:hover {
          background-color: #ffede1 !important;
        }
      }
    }
  }
}

@media (max-width:480px) {
  .table-sites {
    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3),
    th:nth-child(4),
    td:nth-child(4) {
      min-width: 217px !important;
    }
  }
}
</style>